export const useAllSeoMeta = (data, config) => {
	const seoMeta = { ...config.public.seoMeta }

	if (!data) return seoMeta

	// title
	if (data.seo?.metaTitle) seoMeta['title'] = `${data.seo.metaTitle}`
	else if (data.displayTitle) seoMeta['title'] = stripHtml(data.displayTitle)
	else if (data.title !== 'Home') seoMeta['title'] = `${data.title}`

	// ogTitle
	if (data.seo?.metaTitle) seoMeta['ogTitle'] = `${config.public.clientName} — ${data.seo.metaTitle}`
	else if (data.displayTitle) seoMeta['ogTitle'] = `${config.public.clientName} — ` + stripHtml(data.displayTitle)
	else if (data.title !== 'Home') seoMeta['ogTitle'] = `${config.public.clientName} — ${data.title}`

	// description
	if (data.seo?.metaDescription) seoMeta['description'] = data.seo.metaDescription
	else if (data.intro) seoMeta['description'] = stripHtml(data.intro)

	// ogDescription
	if (data.seo?.metaDescription) seoMeta['ogDescription'] = data.seo.metaDescription
	else if (data.intro) seoMeta['ogDescription'] = stripHtml(data.intro)

	// keywords
	if (data.seo?.keywords) seoMeta['keywords'] = data.seo.keywords
	if (config.public.url) seoMeta['ogUrl'] = config.public.frontBase + config.public.url

	// // ogImage
	// if (data.seo?.metaImage?.data) seoMeta['ogImage'] = config.public.mediaBase + '/uploads/s_1200x627,fit_cover/' + data.seo.metaImage.data.attributes.hash + data.seo.metaImage.data.attributes.ext
	if (data.seo?.metaImage?.data) seoMeta['ogImage'] = config.public.mediaBase + '/uploads/s_1200x627,fit_cover/' + data.seo.metaImage.data.attributes.hash + data.seo.metaImage.data.attributes.ext

	return seoMeta
}

